import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Inverse from '@airnz/ui/Inverse';
import PageBlock from '@airnz/ui/PageBlock';
import Form from '@airnz/ui/forms/Form';
import SubmitButton from '@airnz/ui/forms/SubmitButton';
import SelectFormField from '@airnz/ui/forms/SelectFormField';
import Expandable from '@airnz/ui/Expandable';
import LoadingSpinner from '@airnz/ui/LoadingSpinner';
import type { SelectOption } from '@airnz/ui/Select';
import ShowHideTrigger from '@airnz/ui/ShowHideTrigger';
import FormError from '@airnz/ui/FormError';
import { Message } from '@airnz/ui/intl';

import Wrapper from '@common/Wrapper';
import FlightNumberRangeFormField from '@common/FlightNumberRangeFormField';

import messages from '../../FlightPlanSearch.messages';

import {
  AircraftTypeResponse,
  AircraftTypes,
  FlightPlanSearchFormSubmitHandler,
  FlightPlanSearchFormValues,
} from '../../types';

import EarlyReleaseFormField from '../EarlyReleaseFormField';
import AircraftTypeFormField from '../AircraftTypeFormField';

export interface FlightPlanSearchFormProps {
  aircraftTypeOptions: AircraftTypeResponse;
  airportOptions: SelectOption[];
  defaultAirportValue: string;
  onSearch: FlightPlanSearchFormSubmitHandler;
  defaultAllFlights: boolean;
  defaultAircraftTypeValues: AircraftTypes;
}

const SearchFormToggle = styled(ShowHideTrigger)`
  ${({ theme: { spaces } }) => css`
    margin: ${spaces.lg} 0;
  `};
`;

const StyledInverse = styled(Inverse)`
  ${({ theme: { spaces } }) => css`
    margin: 0 0 ${spaces.lg} 0;
  `};
`;

const StyledWrapper = styled(Wrapper)`
  padding-top: 0;
`;

const FlightSearchFormFieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlightPlanSearchForm = ({
  aircraftTypeOptions,
  airportOptions,
  defaultAirportValue,
  onSearch,
  defaultAllFlights,
  defaultAircraftTypeValues,
}: FlightPlanSearchFormProps) => {
  const [open, setOpen] = useState(true);
  const [hasEmptyAircraftType, setHasEmptyAircraftType] = useState(false);
  if (!airportOptions || !aircraftTypeOptions) {
    return <LoadingSpinner />;
  }

  if (airportOptions.length === 0 || aircraftTypeOptions.length === 0) {
    return <Wrapper size="lg" />;
  }

  const onAircraftTypeChange = (values: string[]) => {
    if (values.length) {
      setHasEmptyAircraftType(false);
    } else {
      setHasEmptyAircraftType(true);
    }
  };

  return (
    <StyledInverse>
      <PageBlock size="lg">
        <SearchFormToggle
          ariaControls="expandableSearchForm"
          isShowing={open}
          showLabel="Show search"
          hideLabel="Hide search"
          onClick={() => setOpen(!open)}
        />
      </PageBlock>
      <Expandable open={open} closeDurationMs={200} id="expandableSearchForm">
        <StyledWrapper>
          <PageBlock size="lg">
            <Form<FlightPlanSearchFormValues>
              initialValues={{
                origin: defaultAirportValue,
                flightNumberRange: '',
                allFlights: defaultAllFlights,
                aircraftType: defaultAircraftTypeValues,
              }}
              onSubmit={onSearch}
            >
              <FlightSearchFormFieldsWrapper>
                <FlightNumberRangeFormField name="flightNumberRange" />
                <SelectFormField
                  name="origin"
                  options={airportOptions}
                  ariaLabel="origin"
                  label="Origin"
                  required
                />
              </FlightSearchFormFieldsWrapper>
              <AircraftTypeFormField
                name="aircraftType"
                options={aircraftTypeOptions}
                onChange={onAircraftTypeChange}
              />
              {hasEmptyAircraftType ? (
                <FormError>
                  <Message {...messages.emptyAircraftTypesError} />
                </FormError>
              ) : null}

              <EarlyReleaseFormField />
              <SubmitButton disabled={hasEmptyAircraftType}>
                Search
              </SubmitButton>
            </Form>
          </PageBlock>
        </StyledWrapper>
      </Expandable>
    </StyledInverse>
  );
};

export default FlightPlanSearchForm;
