import React from 'react';

import Button from '@airnz/ui/Button';
import ChevronDown from '@airnz/ui/icons/ChevronDown';
import Popover from '@airnz/ui/Popover';
import useToggle from '@airnz/ui/useToggle';

import { FileLink, FileList } from '@common/styles/FileDownload.styles';

import { DownloadStatusType, DownloadPdfParams } from '@hooks/usePdfDownloader';

import useFlightPlanContext from '@modules/FlightPlanSearch/hooks/useFlightPlanContext';

const FlightPlanFilesPopover = ({
  downloadPdf,
  downloadStatus,
}: {
  downloadPdf: (params: DownloadPdfParams) => void;
  downloadStatus: DownloadStatusType;
}) => {
  const { flightPlan } = useFlightPlanContext();
  const [open, toggleOpen] = useToggle(false);

  return (
    <Popover
      offsetY={5}
      anchor={
        <Button
          icon={<ChevronDown />}
          iconPosition="end"
          onClick={toggleOpen}
          inProgress={downloadStatus === 'pending'}
        >
          Briefing Package
        </Button>
      }
      open={open}
      placement={{ x: 'right', y: 'above' }}
      onEscapePress={toggleOpen}
      onOutsideAction={toggleOpen}
    >
      <FileList>
        {flightPlan.files.map(file => (
          <FileLink
            key={file.fileName}
            target="_blank"
            style={{
              marginLeft: file.nested ? '20pt' : '0',
            }}
            onClick={event => {
              open && toggleOpen();
              downloadPdf({ event, flightPlan, file });
            }}
          >
            {file.type}
          </FileLink>
        ))}
      </FileList>
    </Popover>
  );
};

export default FlightPlanFilesPopover;
