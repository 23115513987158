import styled, { css } from 'styled-components';

import PageActions from '@airnz/ui/PageActions';

export const FlightPlanActions = styled(PageActions)`
  ${({ theme: { media } }) => css`
    margin-bottom: 0;

    ${media.up('md')} {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  `}
`;

export const Action = styled.div`
  ${({ theme: { media } }) => css`
    button,
    .airnz-Popover__anchor {
      width: 100%;
    }

    ${media.up('md')} {
      button,
      .airnz-Popover__anchor {
        width: auto;
      }
    }
  `}
`;
