import React from 'react';
import styled from 'styled-components';

import Button from '@airnz/ui/Button';

import usePdfDownloader from '@hooks/usePdfDownloader';
import { WeatherReport } from '@modules/FlightPlanWeather/types';

export const FileLink = styled.a`
  display: block;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  color: ${({ theme: { palette } }) => palette.tealPrimaryProduct};
`;

const DailyWeatherReportButton = ({ reports }: { reports: WeatherReport }) => {
  const { downloadPdf, downloadStatus } = usePdfDownloader();

  const dailyWeatherReport = reports.weatherReports.filter(
    report => report.type === 'WeatherBrief'
  )[0];

  return (
    <Button inProgress={downloadStatus === 'pending'}>
      <FileLink
        target="_blank"
        onClick={event => {
          dailyWeatherReport
            ? downloadPdf({
                event,
                weatherChart: reports,
                file: dailyWeatherReport,
              })
            : null;
        }}
      >
        Daily NZ Weather Alert
      </FileLink>
    </Button>
  );
};

export default DailyWeatherReportButton;
