import React, { useState } from 'react';
import styled from 'styled-components';

import PageBlock from '@airnz/ui/PageBlock';
import Alert from '@airnz/ui/Alert';
import { Message } from '@airnz/ui/intl';

import Wrapper from '@common/Wrapper';
import TitleBackground from '@common/TitleBackground';
import Skeleton from '@common/Skeleton';

import useAirport from '@hooks/useAirport';

import useSearch from './hooks/useSearch';
import useAircraftType from './hooks/useAircraftType';
import { SearchResultsContext } from './hooks/useSearchResultsContext/useSearchResultsContext';

import FlightPlanSearchForm from './components/FlightPlanSearchForm';
import FlightPlanSearchResults from './components/FlightPlanSearchResults';

import { TimeZone } from './types';

import messages from './FlightPlanSearch.messages';

const StyledWrapper = styled(Wrapper)`
  padding-top: 0;
`;

const FlightPlanSearch = () => {
  const [timeZone, setTimeZone] = useState<TimeZone>('utc');

  const defaultAllFlights = false;

  const {
    status: airportStatus,
    airportOptions,
    defaultAirportValue,
  } = useAirport();

  const {
    status: aircraftTypeStatus,
    aircraftTypeOptions,
    defaultAircraftTypeValues,
  } = useAircraftType();

  const {
    onSearch,
    searchResult: flightPlans,
    status: searchStatus,
  } = useSearch();

  return (
    <SearchResultsContext.Provider value={{ setTimeZone, timeZone }}>
      <TitleBackground>Search flight plans</TitleBackground>
      <FlightPlanSearchForm
        aircraftTypeOptions={aircraftTypeOptions}
        airportOptions={airportOptions}
        defaultAirportValue={defaultAirportValue}
        onSearch={onSearch}
        defaultAllFlights={defaultAllFlights}
        defaultAircraftTypeValues={defaultAircraftTypeValues}
      />
      <StyledWrapper size="lg">
        <PageBlock size="lg">
          {airportStatus === 'failure' || aircraftTypeStatus === 'failure' ? (
            <Alert type="danger">
              <Message {...messages.airportAndAircraftError} />
            </Alert>
          ) : null}

          {(airportStatus === 'success' && airportOptions.length === 0) ||
          (aircraftTypeStatus === 'success' &&
            aircraftTypeOptions.length === 0) ? (
            <Alert type="danger">
              <Message {...messages.airportAndAircraftEmpty} />
            </Alert>
          ) : null}

          {searchStatus === 'failure' ? (
            <Alert type="danger">
              <Message {...messages.searchResultsError} />
            </Alert>
          ) : null}

          {searchStatus === 'pending' ||
          aircraftTypeStatus === 'pending' ||
          airportStatus === 'pending' ? (
            <Skeleton title="search results" height={340}>
              <rect x="0" y="0" rx="6" ry="6" width="100%" height="160" />
              <rect x="0" y="180" rx="6" ry="6" width="100%" height="160" />
            </Skeleton>
          ) : (
            <FlightPlanSearchResults flightPlans={flightPlans} />
          )}
        </PageBlock>
      </StyledWrapper>
    </SearchResultsContext.Provider>
  );
};

export default FlightPlanSearch;
