import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/nextjs';

import apiRequest from '@api/apiRequest';

import { StatusType } from '@common/types';
import { useApiScopes } from '@common/ApiProvider';
import { getAzureAccessToken } from '@common/ApiProvider/getAzureAccessToken';
import { useBannerContext } from '@common/BannerProvider';
import type { BannerMessage } from '@modules/FlightPlanAdmin/types';

import { fuelOrderUrl, bannerUrl } from '@utils/apiUrls';

import { FuelOrderResponse, FuelOrderFlightPlan } from '../../types';

export interface UpdateFuelOrderParams {
  submittedDateTime?: {
    utc: string;
    local: string;
  };
  submittedBy?: string;
  submittedByAddress?: string;
  releaseNumber: number;
  tanker?: number;
  discretionaryFuel?: number;
  taxiFuel?: number;
  payloadFuel?: number;
  restrictedZeroFuelWeight?: number;
  revisedZeroFuelWeight?: number;
  restrictedTakeOffWeight?: number;
  restrictedLandingWeight?: number;
  holdTanker?: boolean;
  refuelRequired?: boolean;
  flightPlanRelease?: string;
}

export interface FuelOrderOutput {
  flightPlan: FuelOrderFlightPlan | undefined;
  fetchStatus: StatusType;
  updateStatus: StatusType;
  fetchFuelOrder: () => Promise<void>;
  updateFuelOrder: (params: UpdateFuelOrderParams) => Promise<void>;
}

const useFuelOrder = (
  flightPlanId: FuelOrderFlightPlan['flightPlanId']
): FuelOrderOutput => {
  const [flightPlan, setFlightPlan] = useState<FuelOrderFlightPlan>();
  const [fetchStatus, setFetchStatus] = useState<StatusType>('idle');
  const [updateStatus, setUpdateStatus] = useState<StatusType>('idle');
  const { instance } = useMsal();
  const { refreshBanner } = useBannerContext();

  const searchScopes = useApiScopes('flightPlan', [
    'fuel.order',
    'flight.plan',
  ]);

  const fetchFuelOrder = useCallback(async () => {
    try {
      setFetchStatus('pending');

      const accessTokenResponse = await getAzureAccessToken(
        instance,
        searchScopes
      );

      const response = await apiRequest<FuelOrderResponse>({
        url: `${fuelOrderUrl}/${flightPlanId}`,
        method: 'GET',
        headers: {
          Authorization: accessTokenResponse,
        },
      });

      setFlightPlan(response.flightPlan);
      setFetchStatus('success');
    } catch {
      setFetchStatus('failure');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightPlanId]);

  const updateFuelOrder = useCallback(
    async (params: UpdateFuelOrderParams) => {
      try {
        setUpdateStatus('pending');

        const accessTokenResponse = await getAzureAccessToken(
          instance,
          searchScopes
        );

        try {
          const bannerResponse = await apiRequest<{
            bannerMessageDto: BannerMessage;
          }>({
            method: 'GET',
            url: bannerUrl,
            headers: {
              Authorization: accessTokenResponse,
            },
          });

          if (refreshBanner) {
            refreshBanner(bannerResponse.bannerMessageDto);
          }
        } catch (error) {
          /* eslint-disable-next-line no-console */
          console.log('Fetch banner failed');
        }

        const response = await apiRequest<FuelOrderResponse>({
          url: `${fuelOrderUrl}/${flightPlanId}`,
          method: 'PATCH',
          headers: {
            Authorization: accessTokenResponse,
          },
          body: {
            ...params,
          },
        });

        setFlightPlan(response.flightPlan);

        setUpdateStatus('success');
      } catch (exception) {
        setUpdateStatus('failure');
        Sentry.captureException(exception);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flightPlanId]
  );

  return {
    flightPlan,
    fetchStatus,
    updateStatus,
    fetchFuelOrder,
    updateFuelOrder,
  };
};

export default useFuelOrder;
