import React, { useEffect } from 'react';

import useFlightPlanContext from '@modules/FlightPlanSearch/hooks/useFlightPlanContext';
import useFuelOrderData from '@modules/FlightPlanSearch/hooks/useFuelOrderData/useFuelOrderData';

import FuelOrderFuelTable from '../FuelOrderFuelTable';
import FuelOrderWeightTable from '../FuelOrderWeightTable';

import { FuelOrderFlightPlan } from '@modules/FlightPlanSearch/types';

const FuelOrderTables = ({
  flightPlan,
}: {
  flightPlan: FuelOrderFlightPlan;
}) => {
  const { setFuelOrderData, isTurboProp, isOrderFuelDisabled } =
    useFlightPlanContext();
  const fuelOrderData = useFuelOrderData(flightPlan);

  useEffect(() => {
    setFuelOrderData(fuelOrderData);
  }, [fuelOrderData, setFuelOrderData]);

  return (
    <>
      <FuelOrderFuelTable
        flightPlan={flightPlan}
        fuelOrderData={fuelOrderData}
        isTurboProp={isTurboProp}
        isOrderFuelDisabled={isOrderFuelDisabled}
      />
      <FuelOrderWeightTable
        flightPlan={flightPlan}
        fuelOrderData={fuelOrderData}
        isOrderFuelDisabled={isOrderFuelDisabled}
      />
    </>
  );
};

export default FuelOrderTables;
