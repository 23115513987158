import { useCallback } from 'react';

import { useIntl } from '@airnz/ui/intl';

const useFuelOrderTextFieldValidator = ({
  min = 0,
  max,
}: {
  min?: number;
  max?: number;
}) => {
  const intl = useIntl();

  const validator = useCallback(
    (value: string) => {
      const numberValue = Number(value);

      const invalidMax = max && numberValue > max;
      const invalidMin = numberValue < min;

      if (max !== undefined && (invalidMax || invalidMin)) {
        return `Please enter value between ${intl.formatNumber(
          min
        )} and ${intl.formatNumber(max)}`;
      }

      if (!invalidMax && invalidMin) {
        return `Please enter value higher than ${intl.formatNumber(min)}`;
      }

      return undefined;
    },
    [intl, min, max]
  );
  return validator;
};

export default useFuelOrderTextFieldValidator;
