import React from 'react';

import { Message } from '@airnz/ui/intl';

import { StyledFuelOrderedAlert } from './FuelOrderedAlert.styles';

import messages from './FuelOrderedAlert.messages';

const FuelOrderedAlert = () => (
  <StyledFuelOrderedAlert type="success" minimal>
    <Message {...messages.fuelOrdered} />
  </StyledFuelOrderedAlert>
);

export default FuelOrderedAlert;
