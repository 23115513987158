import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const getIsPassedDeparture = (utcTimeString: string) => {
  dayjs.extend(utc);

  const startTime = dayjs.utc();
  const etd = dayjs.utc(utcTimeString);

  return startTime.isAfter(etd);
};
