import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import { StatusType } from '@common/types';
import { aircraftTypesUrl } from '@utils/apiUrls';
import { getAzureAccessToken } from '@common/ApiProvider/getAzureAccessToken';
import apiRequest from '@api/apiRequest';

import { AircraftTypeResponse, AircraftTypes } from '../../types';
import { useApiScopes } from '@common/ApiProvider';

const useAircraftType = () => {
  const [data, setData] = useState<AircraftTypeResponse>([]);

  const allJetTypes = ['A20N', 'A21N', 'A320', 'B77W', 'B789'];
  const aircraftTypesFromApi = data.map(aircraftType => aircraftType.value);
  const mergeAircraftTypes = [...allJetTypes, ...aircraftTypesFromApi];
  const selectedDefaultValues = [...new Set(mergeAircraftTypes)];

  const [status, setStatus] = useState<StatusType>('idle');

  const { instance } = useMsal();

  const aircraftScopes = useApiScopes('flightPlan', [
    'fuel.order',
    'flight.plan',
  ]);

  useEffect(() => {
    const handleAircraftType = async () => {
      setStatus('pending');

      try {
        const accessTokenResponse = await getAzureAccessToken(
          instance,
          aircraftScopes
        );
        const response = await apiRequest<{ aircraftTypes: AircraftTypes }>({
          method: 'GET',
          url: aircraftTypesUrl,
          headers: {
            Authorization: accessTokenResponse,
          },
        });

        const responseData = response.aircraftTypes
          ? response.aircraftTypes.map(type => ({
              id: type,
              label: type,
              value: type,
            }))
          : [];

        setData(responseData);
        setStatus('success');
      } catch {
        setStatus('failure');
      }
    };

    handleAircraftType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return {
    defaultAircraftTypeValues: selectedDefaultValues || [],
    aircraftTypeOptions: data,
    status,
  };
};

export default useAircraftType;
