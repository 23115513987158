import React from 'react';
import { useForm } from 'react-final-form';

import { TextFieldProps } from '@airnz/ui/TextField';
import TextFormField from '@airnz/ui/forms/TextFormField';

import useFlightNumberRangeValidator from './useFlightNumberRangeValidator';
import flightNumberRangeNormalizer from './flightNumberRangeNormalizer';

interface FlightNumberRangeFormFieldProps
  extends Omit<TextFieldProps, 'value' | 'error' | 'id' | 'type'> {
  id?: string;
  name: string;
}

const FlightNumberRangeFormField = ({
  id,
  name,
  ...props
}: FlightNumberRangeFormFieldProps) => {
  const form = useForm();

  const validator = useFlightNumberRangeValidator();

  return (
    <TextFormField
      id={id}
      name={name}
      label="Flight number(s)"
      placeholder="0000-9999"
      hint="Please enter a flight number or a range separated by hyphen."
      normalize={flightNumberRangeNormalizer}
      validator={validator}
      autoComplete="off"
      onFocus={() => {
        form.change(name, '');
      }}
      {...props}
    />
  );
};

export default FlightNumberRangeFormField;
