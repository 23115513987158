import React, { useState, useEffect } from 'react';

import Alert from '@airnz/ui/Alert';
import SegmentedControl from '@airnz/ui/SegmentedControl';
import { IntlDateTimeUtc } from '@airnz/ui/intl';

import useSearchResultsContext from '@modules/FlightPlanSearch/hooks/useSearchResultsContext';
import useReport from '@modules/FlightPlanWeather/hooks/useReport';

import { FlightPlan, SearchResultFlightPlan, TimeZone } from '../../types';

import FlightPlanAccordionItem from '../FlightPlanAccordionItem';

import {
  TitleBar,
  ResultsLastChangedDate,
  ResultsAction,
  ResultsActions,
  StyledH2,
} from './FlightPlanSearchResults.styles';

const FlightPlanSearchResults = ({
  flightPlans,
}: {
  flightPlans: SearchResultFlightPlan[];
}) => {
  const [initialised, setInitialised] = useState(false);
  const [resultsLastChanged, setResultsLastChangedDate] = useState<Date>();
  const [expandedFlightPlanIds, setExpandedFlightPlanIds] = useState<
    FlightPlan['flightPlanId'][]
  >([]);

  const { timeZone, setTimeZone } = useSearchResultsContext();

  const { data: weatherReport, status: weatherReportStatus } = useReport();

  useEffect(() => {
    setResultsLastChangedDate(new Date());
  }, [flightPlans]);

  useEffect(() => {
    if (!flightPlans || flightPlans.length !== 1 || initialised) {
      return;
    }

    // Automatically expand if there is only one flight plan
    setExpandedFlightPlanIds([flightPlans[0].flightPlanId]);
    setInitialised(true);
  }, [flightPlans, initialised]);

  if (!flightPlans) {
    return null;
  }

  const toggleFlightPlanAccordionItem = (
    flightPlanId: SearchResultFlightPlan['flightPlanId']
  ) => {
    if (expandedFlightPlanIds.includes(flightPlanId)) {
      setExpandedFlightPlanIds(previousState =>
        previousState.filter(id => id !== flightPlanId)
      );
      return;
    }

    setExpandedFlightPlanIds(previousState => [...previousState, flightPlanId]);
  };

  return (
    <>
      <TitleBar>
        <div>
          <StyledH2>Search results</StyledH2>
          {resultsLastChanged ? (
            <ResultsLastChangedDate>
              <IntlDateTimeUtc
                value={resultsLastChanged.toISOString()}
                timeZone={timeZone}
                hourCycle="h23"
              />
            </ResultsLastChangedDate>
          ) : null}
        </div>

        <ResultsActions>
          <ResultsAction>
            <SegmentedControl
              id="timeZone"
              value={timeZone}
              ariaLabel="Timezone"
              options={[
                {
                  value: 'user',
                  label: 'Local time',
                },
                {
                  value: 'utc',
                  label: 'UTC time',
                },
              ]}
              onChange={(value: TimeZone) => setTimeZone(value)}
            />
          </ResultsAction>
        </ResultsActions>
      </TitleBar>

      {flightPlans.length === 0 ? <Alert type="info">No results.</Alert> : null}

      <div>
        {flightPlans.map((flightPlan: SearchResultFlightPlan, i: number) => (
          <FlightPlanAccordionItem
            key={flightPlan.flightPlanId}
            flightPlan={flightPlan}
            onToggle={toggleFlightPlanAccordionItem}
            expanded={expandedFlightPlanIds.includes(flightPlan.flightPlanId)}
            hasNextExpanded={expandedFlightPlanIds.includes(
              flightPlans[i + 1]?.flightPlanId
            )}
            weatherReport={weatherReport}
            weatherReportStatus={weatherReportStatus}
          />
        ))}
      </div>
    </>
  );
};

export default FlightPlanSearchResults;
