import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import * as Sentry from '@sentry/nextjs';

import apiRequest from '@api/apiRequest';
import { StatusType } from '@common/types';
import { useApiScopes } from '@common/ApiProvider';
import { getAzureAccessToken } from '@common/ApiProvider/getAzureAccessToken';
import { WeatherReport } from '@modules/FlightPlanWeather/types';
import { weatherReportUrl } from '@utils/apiUrls';

const useReport = () => {
  const [data, setData] = useState<WeatherReport>({ weatherReports: [] });
  const [status, setStatus] = useState<StatusType>('idle');
  const { instance } = useMsal();

  const weatherReportScopes = useApiScopes('flightPlan', [
    'fuel.order',
    'flight.plan',
  ]);

  useEffect(() => {
    const handleReports = async () => {
      setStatus('pending');

      try {
        const accessTokenResponse = await getAzureAccessToken(
          instance,
          weatherReportScopes
        );
        const response = await apiRequest<WeatherReport>({
          method: 'GET',
          url: weatherReportUrl,
          headers: {
            Authorization: accessTokenResponse,
          },
        });

        setData(response);
        setStatus('success');
      } catch (exception) {
        setStatus('failure');
        Sentry.captureException(exception);
      }
    };

    handleReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return {
    data,
    status,
  };
};

export default useReport;
