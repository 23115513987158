import React from 'react';

import Alert from '@airnz/ui/Alert';
import { Message, IntlNumber } from '@airnz/ui/intl';

import useFlightPlanContext from '@modules/FlightPlanSearch/hooks/useFlightPlanContext';

import FuelOrderLoader from './components/FuelOrderLoader';
import FuelOrderTables from './components/FuelOrderTables';

import messages from './FuelOrder.messages';

export const renderRowValue = (value?: string | number) => {
  if (value === undefined) {
    return '';
  }

  if (typeof value === 'number') {
    return <IntlNumber value={value} />;
  }

  return value;
};

const FuelOrder = () => {
  const { fuelOrderFlightPlan: flightPlan, fuelOrderFetchStatus: fetchStatus } =
    useFlightPlanContext();

  if (fetchStatus === 'failure') {
    return (
      <Alert type="danger">
        <Message {...messages.fuelOrderError} />
      </Alert>
    );
  }

  if (fetchStatus !== 'success' || !flightPlan) {
    return (
      <>
        <FuelOrderLoader />
        <FuelOrderLoader />
      </>
    );
  }

  return <FuelOrderTables flightPlan={flightPlan} />;
};

export default FuelOrder;
