import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { ColorModeProvider } from '@airnz/ui/ColorMode';
import useToggle from '@airnz/ui/useToggle';
import ArrowPopover from '@airnz/ui/ArrowPopover';
import FieldLabel from '@airnz/ui/FieldLabel';
import Chip from '@airnz/ui/Chip';
import Dialog from '@airnz/ui/Dialog';
import CheckboxGroupFormField from '@airnz/ui/forms/CheckboxGroupFormField';
import { CheckboxGroupFieldProps } from '@airnz/ui/CheckboxGroupField';
import useFormField from '@airnz/ui/forms/useFormField';

export interface AircraftTypeOption {
  id: string;
  label: string;
  value: string;
}

export interface AircraftTypeFormFieldProps
  extends Omit<
    CheckboxGroupFieldProps<string>,
    'value' | 'error' | 'id' | 'type' | 'options'
  > {
  id?: string;
  name: string;
  options: AircraftTypeOption[];
}

const CheckboxGroupFormFieldWrapper = styled.div`
  column-count: 2;
`;

const StyledField = styled.div`
  ${({ theme: { media, spaces } }) => css`
    margin-bottom: ${spaces.lg};

    ${media.up('md')} {
      margin-bottom: ${spaces.md};
    }
  `}
`;

const getChipLabel = (
  options: AircraftTypeOption[],
  selectedValues: Array<string>
): string => {
  const displayedValuesCount = 3;
  const displayedValues = selectedValues
    .slice(0, displayedValuesCount)
    .join(', ');
  const remainderCount = Math.max(
    selectedValues.length - displayedValuesCount,
    0
  );

  if (!selectedValues.length) {
    return 'None';
  }

  if (options.length === selectedValues.length) {
    return 'All';
  }
  return remainderCount
    ? `${displayedValues} + ${remainderCount}`
    : displayedValues;
};

const AircraftTypeFormField = ({
  id,
  name,
  options,
  onChange,
}: AircraftTypeFormFieldProps) => {
  const { value } = useFormField<Array<string>>({ name });
  const initialChiplabel = getChipLabel(options, value);
  const [open, toggleOpen] = useToggle(false);
  const [chipLabel, setChipLabel] = useState<string>(initialChiplabel);
  useEffect(() => {
    setChipLabel(getChipLabel(options, value));
  }, [options, value]);

  return (
    <StyledField>
      <ColorModeProvider onDark={false} onColor={false}>
        {({ colorModeClassName }) => (
          <ArrowPopover
            anchor={
              <>
                <FieldLabel required>Aircraft type</FieldLabel>
                <Chip type="trigger" selected onClick={toggleOpen} hideDeselect>
                  {chipLabel}
                </Chip>
              </>
            }
            open={open}
            onOutsideAction={toggleOpen}
          >
            <Dialog
              size="sm"
              onClose={toggleOpen}
              className={colorModeClassName}
            >
              <CheckboxGroupFormFieldWrapper>
                <CheckboxGroupFormField<string>
                  id={id || 'aircraft-types'}
                  name={name}
                  options={options}
                  showSelectAll
                  onChange={onChange}
                />
              </CheckboxGroupFormFieldWrapper>
            </Dialog>
          </ArrowPopover>
        )}
      </ColorModeProvider>
    </StyledField>
  );
};

export default AircraftTypeFormField;
