import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
/**
 * Jira FPR-3597. User is not allowed to order fuel before 2 hours from departure time.
 * So this function is to check if the given time string is the same or after 2hrs from now.
 *
 * e.g
 * current utc 2022-09-13T11:00:00.000Z
 * then given
 * Departure time in utc:                Returns
 * 2022-09-13T10:00:00.000Z               false (Not allowed to order fuel for flights before "now")
 * 2022-09-13T11:00:00.000Z               true (Allowed to order fuel as it is "now"")
 * 2022-09-13T12:00:00.000Z               true (Allowed to order fuel as it is within the limit of 2 hours)
 * 2022-09-13T13:00:00.000Z               true (Allowed to order fuel as it is at the limit of 2 hours)
 * 2022-09-14T11:00:00.000Z               false (Not allowed to order fuel as it is after the limit of 2 hours)
 *
 * @param utcTimeString a utc time string.
 *
 * @returns true if provided utcTimeString is after or at 2hrs from current utc time, false otherwise.
 *
 */
export const getIsDepartureWithin2hours = (utcTimeString: string) => {
  // dayjs uses isSameOrAfter and utc plugin
  dayjs.extend(isBetween);
  dayjs.extend(utc);

  const startTime = dayjs.utc();
  const endTime = startTime.add(2, 'hours');
  const etd = dayjs.utc(utcTimeString);

  return etd.isBetween(startTime, endTime, 'second', '[]');
};
