import React from 'react';

import Alert from '@airnz/ui/Alert';
import { Message } from '@airnz/ui/intl';
import SubmitButton from '@airnz/ui/forms/SubmitButton';
import ToastDialog from '@airnz/ui/ToastDialog';
import Button from '@airnz/ui/Button';

import usePdfDownloader from '@hooks/usePdfDownloader';

import WeatherChartsPopover from '@modules/FlightPlanWeather/components/WeatherReports/WeatherChartsPopover';

import useFlightPlanContext from '@modules/FlightPlanSearch/hooks/useFlightPlanContext';

import FuelOrder from '@modules/FlightPlanSearch/components/FuelOrder';
import FuelOrderedAlert from '@modules/FlightPlanSearch/components/FuelOrderedAlert';
import DailyWeatherReportButton from '@modules/FlightPlanWeather/components/WeatherReports/DailyWeatherReportButton';

import FlightPlanDetailsTable from './components/FlightPlanDetailsTable';
import FlightPlanFilesPopover from './components/FlightPlanFilesPopover';

import { Action, FlightPlanActions } from './FlightPlan.styles';

import messages from './FlightPlan.messages';

const FlightPlan = () => {
  const {
    userCanOrderFuel,
    isDraftRelease,
    isOrderFuelDisabled,
    flightPlan,
    fuelOrderFlightPlan,
    fuelOrderUpdateStatus,
    weatherReport,
    weatherReportStatus,
    fuelOrderData,
  } = useFlightPlanContext();

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const { downloadPdf, downloadStatus } = usePdfDownloader();

  const fuelOrderExist = flightPlan.fuelOrder || fuelOrderFlightPlan?.fuelOrder;

  return (
    <>
      <ToastDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        heading={
          fuelOrderExist ? (
            <Message {...messages.confirmationWarningText} />
          ) : (
            <Message {...messages.confirmationText} />
          )
        }
        actions={[
          <SubmitButton
            key="primary"
            onClick={() => setOpenConfirmation(false)}
          >
            <Message {...messages.confirmationContinue} />
          </SubmitButton>,
          <Button key="secondary" onClick={() => setOpenConfirmation(false)}>
            <Message {...messages.confirmationCancel} />
          </Button>,
        ]}
      />

      {isDraftRelease ? (
        <Alert type="warning">
          <Message {...messages.earlyReleaseWarning} />
        </Alert>
      ) : null}

      <FlightPlanDetailsTable />

      <FuelOrder />

      <FlightPlanActions direction="row">
        <Action>
          <FlightPlanFilesPopover
            downloadPdf={downloadPdf}
            downloadStatus={downloadStatus}
          />
        </Action>

        <Action>
          <WeatherChartsPopover
            reports={weatherReport}
            placement={{ x: 'right', y: 'above' }}
            loading={weatherReportStatus === 'pending'}
          />
        </Action>

        <DailyWeatherReportButton reports={weatherReport} />

        {userCanOrderFuel && fuelOrderData ? (
          <Action>
            <Button
              disabled={isOrderFuelDisabled}
              primary
              inProgress={fuelOrderUpdateStatus === 'pending'}
              onClick={() => setOpenConfirmation(true)}
            >
              <Message {...messages.orderFuel} />
            </Button>
          </Action>
        ) : null}

        {flightPlan.fuelOrder || fuelOrderFlightPlan?.fuelOrder ? (
          <Action>
            <FuelOrderedAlert />
          </Action>
        ) : null}
      </FlightPlanActions>

      {fuelOrderUpdateStatus === 'failure' ? (
        <Alert type="danger">
          <Message {...messages.orderFuelError} />
        </Alert>
      ) : null}

      {downloadStatus === 'failure' ? (
        <Alert type="danger">
          <Message {...messages.downloadError} />
        </Alert>
      ) : null}
    </>
  );
};

export default FlightPlan;
