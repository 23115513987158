import React from 'react';

import Table, { TBody, Tr, Td } from '@airnz/ui/Table';
import { IntlDateTimeUtc } from '@airnz/ui/intl';

import useFlightPlanContext from '@modules/FlightPlanSearch/hooks/useFlightPlanContext';
import useSearchResultsContext from '@modules/FlightPlanSearch/hooks/useSearchResultsContext';
import { timeZonePhrasing } from '@modules/FlightPlanSearch/utils/timeZonePhrasing';

import { TitleCol } from './FlightPlanDetailsTable.styles';

const FlightPlanDetailsTable = () => {
  const { flightPlan } = useFlightPlanContext();
  const { timeZone } = useSearchResultsContext();

  return (
    <Table borderBottom borderTop>
      <TBody>
        <Tr>
          <TitleCol>FLIGHT</TitleCol>
          <Td>
            {flightPlan.commercialFlightNumber} -{' '}
            {flightPlan.aircraftRegistration}
          </Td>
        </Tr>

        <Tr>
          <TitleCol>ROUTE</TitleCol>
          <Td>
            {flightPlan.departureAirport.airportIataCode} -{' '}
            {flightPlan.destinationAirport.airportIataCode}
          </Td>
        </Tr>

        <Tr>
          <TitleCol>AIRCRAFT TYPE</TitleCol>
          <Td>{flightPlan.aircraftType}</Td>
        </Tr>

        <Tr>
          <TitleCol>STD ({timeZonePhrasing(timeZone)})</TitleCol>
          <Td>
            <IntlDateTimeUtc
              value={flightPlan.scheduledDepartureDateTime.utc}
              timeZone={timeZone}
              hourCycle="h23"
            />
          </Td>
        </Tr>

        <Tr>
          <TitleCol>ETD ({timeZonePhrasing(timeZone)})</TitleCol>
          <Td>
            <IntlDateTimeUtc
              value={
                flightPlan.estimatedDepartureDateTime?.utc ??
                flightPlan.scheduledDepartureDateTime.utc
              }
              timeZone={timeZone}
              hourCycle="h23"
            />
          </Td>
        </Tr>

        <Tr>
          <TitleCol>CAPTAIN</TitleCol>
          <Td>{flightPlan.captainName ?? 'N/A'}</Td>
        </Tr>
      </TBody>
    </Table>
  );
};

export default FlightPlanDetailsTable;
