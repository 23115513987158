import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';

import Alert from '@airnz/ui/Alert';
import { Message } from '@airnz/ui/intl';
import Table, { THead, TBody, Tr } from '@airnz/ui/Table';

import { FuelOrderData } from '@modules/FlightPlanSearch/hooks/useFuelOrderData';

import {
  FuelCol,
  StyledValue,
  FuelTitle,
  FuelTitleCol,
  renderRowValue,
  FuelOrderWrapper,
  FuelOrderTextField,
  FuelTitleTextWrapper,
} from '@modules/FlightPlanSearch/components/FuelOrder';

import {
  FuelOrderRow,
  FuelOrderFlightPlan,
} from '@modules/FlightPlanSearch/types';

import useUser from '@hooks/useUser';

import messages from './FuelOrderFuelTable.messages';

const FuelOrderFuelTable = ({
  flightPlan,
  fuelOrderData,
  isTurboProp,
  isOrderFuelDisabled,
}: {
  flightPlan: FuelOrderFlightPlan;
  fuelOrderData: FuelOrderData;
  isTurboProp: boolean;
  isOrderFuelDisabled: boolean;
}) => {
  const form = useForm();
  const user = useUser();

  const {
    minimumFlightPlanFuel,
    minimumReserveFuel,
    maximumFuelCapacity,
    tanker,
    fpExtra,
    taxiFuel,
    toFuel,
    toFuelRevised,
    totalTankFuel,
    totalTankFuelRevised,
    fuelCapacityExceeded,
  } = fuelOrderData;

  const isTankerDisabled = !user?.canOrderFuel && !user?.canSeeAdminPage;

  const tankerRevisedField =
    minimumReserveFuel === 0
      ? { revised: minimumReserveFuel }
      : {
          revisedNode: (
            <FuelOrderTextField
              name="tanker"
              ariaLabel="Tanker"
              max={50000}
              isOrderFuelDisabled={isOrderFuelDisabled || isTankerDisabled}
            />
          ),
        };

  useEffect(() => {
    form.change('tanker', `${flightPlan.fuelOrder?.tanker ?? (tanker || '')}`);

    form.change(
      'discretionaryFuel',
      `${flightPlan.fuelOrder?.discretionaryFuel || ''}`
    );

    form.change(
      'taxiFuel',
      `${flightPlan.fuelOrder?.taxiFuel ?? (taxiFuel || '')}`
    );

    form.change('payloadFuel', `${flightPlan.fuelOrder?.payloadFuel || ''}`);
  }, [flightPlan, form, taxiFuel, tanker]);

  const fuelOrderRows: FuelOrderRow[] = [
    {
      id: 'minFpFuel',
      title: 'MIN FP FUEL',
      planned: minimumFlightPlanFuel,
      revised: minimumFlightPlanFuel,
    },
    {
      id: 'tanker',
      title: 'TANKER',
      planned: minimumReserveFuel,
      ...tankerRevisedField,
    },
    {
      id: 'fpExtra',
      title: 'FP EXTRA',
      planned: fpExtra,
      revised: fpExtra,
    },
    {
      id: 'discFuel',
      title: 'DISC FUEL',
      revisedNode: (
        <FuelOrderTextField
          name="discretionaryFuel"
          ariaLabel="Disc Fuel (kg)"
          max={50000}
          isOrderFuelDisabled={isOrderFuelDisabled}
        />
      ),
    },
    {
      id: 'toFuel',
      title: 'TO FUEL',
      planned: toFuel,
      revised: toFuelRevised,
    },
    {
      id: 'taxiFuel',
      title: 'TAXI OUT',
      planned: taxiFuel,
      revisedNode: (
        <FuelOrderTextField
          name="taxiFuel"
          ariaLabel="Taxi Fuel (kg)"
          max={10000}
          isOrderFuelDisabled={isOrderFuelDisabled}
        />
      ),
    },
    {
      id: 'payloadFuel',
      title: 'UNUSABLE FUEL',
      revisedNode: (
        <FuelOrderTextField
          name="payloadFuel"
          ariaLabel="UNUSABLE FUEL (kg)"
          isOrderFuelDisabled={isOrderFuelDisabled || isTurboProp}
        />
      ),
    },
    {
      id: 'totalFuel',
      title: 'TOTAL FUEL',
      planned: totalTankFuel,
      revisedNode: (
        <StyledValue error={fuelCapacityExceeded}>
          {renderRowValue(totalTankFuelRevised)}
          {fuelCapacityExceeded ? (
            <div data-testid="fuel_totalFuel_error">
              <Alert minimal type="danger">
                <Message {...messages.fuelCapacityExceeded} />
              </Alert>
            </div>
          ) : null}
        </StyledValue>
      ),
      structural: maximumFuelCapacity,
    },
  ];

  return (
    <FuelOrderWrapper mb>
      <Table borderBottom>
        <THead>
          <Tr>
            <FuelTitle>FUEL (KG)</FuelTitle>
            <FuelTitle numeric>PLANNED</FuelTitle>
            <FuelTitle numeric>REVISED</FuelTitle>
            <FuelTitle />
            <FuelTitle numeric>STRUCTURAL</FuelTitle>
          </Tr>
        </THead>

        <TBody>
          {fuelOrderRows.map(row => (
            <Tr key={row.title}>
              <FuelTitleCol>
                <FuelTitleTextWrapper>{row.title}</FuelTitleTextWrapper>
              </FuelTitleCol>

              <FuelCol numeric data-testid={`fuel_${row.id}_planned`}>
                <FuelTitleTextWrapper>
                  {renderRowValue(row.planned)}
                </FuelTitleTextWrapper>
              </FuelCol>

              <FuelCol numeric data-testid={`fuel_${row.id}_revised`}>
                <FuelTitleTextWrapper>
                  {row.revisedNode ?? renderRowValue(row.revised)}
                </FuelTitleTextWrapper>
              </FuelCol>

              <FuelCol />

              <FuelCol numeric data-testid={`fuel_${row.id}_structural`}>
                <FuelTitleTextWrapper>
                  {renderRowValue(row.structural)}
                </FuelTitleTextWrapper>
              </FuelCol>
            </Tr>
          ))}
        </TBody>
      </Table>
    </FuelOrderWrapper>
  );
};

export default FuelOrderFuelTable;
