export default {
  earlyReleaseWarning: {
    id: 'flightPlanPortal.flightPlan.earlyReleaseWarning',
    defaultMessage: 'Draft release plan - details are not yet final.',
  },
  downloadError: {
    id: 'flightPlanPortal.flightPlan.downloadError',
    defaultMessage: 'Fetching PDF for download failed.',
  },
  orderFuelError: {
    id: 'flightPlanPortal.flightPlan.orderFuelError',
    defaultMessage:
      'Fuel order failed. Check the RELEASE number of your plan (a new one may have been released) and refresh the page and submit again.',
  },
  orderFuel: {
    id: 'flightPlanPortal.flightPlan.orderFuel',
    defaultMessage: 'Order fuel',
  },
  confirmationText: {
    id: 'flightPlanPortal.flightPlan.confirmationText',
    defaultMessage: 'Press Continue to submit the fuel order',
  },
  confirmationWarningText: {
    id: 'flightPlanPortal.flightPlan.confirmationWarningText',
    defaultMessage:
      'A previous Flight Plan Portal fuel order has already been made, do you want to continue?',
  },
  confirmationContinue: {
    id: 'flightPlanPortal.flightPlan.confirmationContinue',
    defaultMessage: 'Continue',
  },
  confirmationCancel: {
    id: 'flightPlanPortal.flightPlan.confirmationCancel',
    defaultMessage: 'Cancel',
  },
};
