import React from 'react';
import styled, { css } from 'styled-components';

import PageHeading from '@airnz/ui/PageHeading';
import PageBlock from '@airnz/ui/PageBlock';

const Heading = styled(PageHeading)`
  ${({ theme: { palette, fontSize } }) => css`
    color: ${palette.white};
    ${fontSize.mega};
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
  `};
`;

const Background = styled.div`
  ${({ theme: { palette, spaces, media } }) => css`
    // background given to me by sam ross
    background: linear-gradient(
      90deg,
      #440099 -1.87%,
      #000009 18.89%,
      #010122 51.15%,
      #221551 102.01%
    );
    background-size: 800% 800%;
    min-height: 150px;
    color: ${palette.white};
    padding: ${spaces.lg} 0;
    display: flex;

    ${media.up('md')} {
      min-height: 250px;
    }
  `};
`;

interface TitleBackgroundProps {}

const TitleBackground = ({
  children,
}: React.PropsWithChildren<TitleBackgroundProps>) => (
  <Background>
    <PageBlock size="lg">
      <Heading>{children}</Heading>
    </PageBlock>
  </Background>
);

export default TitleBackground;
