import React from 'react';
import styled, { css } from 'styled-components';

import Switch from '@airnz/ui/Switch';
import FieldLabel from '@airnz/ui/FieldLabel';

import useFormField from '@airnz/ui/forms/useFormField';
import useUser from '@hooks/useUser';

const StyledField = styled.div`
  ${({ theme: { media, spaces } }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spaces.lg};

    ${media.up('md')} {
      margin-bottom: ${spaces.md};
    }
  `}
`;

const StyledSwitch = styled(Switch)`
  align-self: flex-start;
`;

const EarlyReleaseFormField = () => {
  const user = useUser();
  const fieldProps = useFormField<boolean>({
    name: 'allFlights',
  });

  return (
    <StyledField>
      {user?.canSeeDraftPlans && (
        <>
          <FieldLabel htmlFor="releasesFilter" required>
            Include draft release plans
          </FieldLabel>
          <StyledSwitch {...fieldProps} />
        </>
      )}
    </StyledField>
  );
};

export default EarlyReleaseFormField;
