import { createContext, useContext } from 'react';

import { TimeZone } from '@modules/FlightPlanSearch/types';

export interface SearchResultsContextOutput {
  timeZone: TimeZone;
  setTimeZone: React.Dispatch<React.SetStateAction<TimeZone>>;
}

export const SearchResultsContext = createContext<
  SearchResultsContextOutput | undefined
>(undefined);

const useSearchResultsContext = (): SearchResultsContextOutput => {
  const searchResultsContext = useContext(SearchResultsContext);

  if (!searchResultsContext) {
    throw new Error(
      'No SearchResultsContext.Provider found when calling useSearchResultsContext.'
    );
  }

  return searchResultsContext;
};

export default useSearchResultsContext;
