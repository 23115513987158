import styled, { css } from 'styled-components';
import { SpaceSize } from '@airnz/ui-tokens/spaces';

const Wrapper = styled.div<{ size?: SpaceSize }>`
  ${({ theme: { spaces }, size }) => css`
    padding: ${size ? spaces[size] : spaces.xl} 0;
  `};
`;

export default Wrapper;
