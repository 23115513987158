import React from 'react';
import { useForm } from 'react-final-form';

import { useIntl } from '@airnz/ui/intl';

import useFuelOrderTextFieldValidator from './useFuelOrderTextFieldValidator';

import { StyledTextField } from './FuelOrderTextField.styles';

const FuelOrderTextField = ({
  name,
  ariaLabel,
  min,
  max,
  hasValidator = true,
  hasError = false,
  isOrderFuelDisabled,
}: {
  name: string;
  ariaLabel: string;
  min?: number;
  max?: number;
  hasValidator?: boolean;
  hasError?: boolean;
  isOrderFuelDisabled: boolean;
}) => {
  const intl = useIntl();
  const form = useForm();
  const validator = useFuelOrderTextFieldValidator({ min, max });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow keyboard shortcuts
    if (
      event.getModifierState('Meta') ||
      event.getModifierState('Control') ||
      event.getModifierState('Alt')
    ) {
      return;
    }

    const { key } = event;

    // Allow non-printable keys, excluding "Enter"
    if (key !== 'Enter' && (key.length !== 1 || key === '\x00')) {
      return;
    }

    // Prevent any non-numeric keys
    if (key < '0' || key > '9') {
      event.preventDefault();
    }
  };

  const handleOnChange = (name: string) => () => {
    const formState = form.getState();
    const isFieldExist = Object.keys(formState.values).includes(name);

    if (!isFieldExist) {
      form.change(name, 0);
    }
  };

  return (
    <StyledTextField
      hasError={hasError}
      id={name}
      ariaLabel={ariaLabel}
      name={name}
      disabled={isOrderFuelDisabled}
      type="tel"
      validator={hasValidator ? validator : undefined}
      autoComplete="off"
      preserveWhiteSpace
      maxLength={10}
      format={value => intl.formatNumber(Number(value))}
      normalize={value => value.replace(/\D/g, '')}
      onKeyDown={handleKeyDown}
      onChange={handleOnChange(name)}
    />
  );
};

export default FuelOrderTextField;
