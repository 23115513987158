import { useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';

import apiRequest from '@api/apiRequest';

import { StatusType } from '@common/types';
import { useApiScopes } from '@common/ApiProvider';
import { getAzureAccessToken } from '@common/ApiProvider/getAzureAccessToken';
import { searchUrl, bannerUrl } from '@utils/apiUrls';

import { SearchResponse, FlightPlanSearchFormSubmitHandler } from '../../types';
import type { BannerMessage } from '@modules/FlightPlanAdmin/types';
import { useBannerContext } from '@common/BannerProvider';

interface SearchParams {
  flightNumberRange?: string;
  departureAirportCode?: string;
  allFlights?: boolean;
  aircraftType?: string;
}

const useSearch = () => {
  const [data, setData] = useState<any>([]);
  const [status, setStatus] = useState<StatusType>('idle');
  const { instance } = useMsal();
  const { refreshBanner } = useBannerContext();

  const searchScopes = useApiScopes('flightPlan', [
    'fuel.order',
    'flight.plan',
  ]);

  const search = useCallback(
    async (searchParams: SearchParams) => {
      try {
        const accessTokenResponse = await getAzureAccessToken(
          instance,
          searchScopes
        );

        try {
          const bannerResponse = await apiRequest<{
            bannerMessageDto: BannerMessage;
          }>({
            method: 'GET',
            url: bannerUrl,
            headers: {
              Authorization: accessTokenResponse,
            },
          });

          if (refreshBanner) {
            refreshBanner(bannerResponse.bannerMessageDto);
          }
        } catch (error) {
          /* eslint-disable-next-line no-console */
          console.log('Fetch banner failed');
        }

        setStatus('pending');
        const response = await apiRequest<SearchResponse>({
          url: searchUrl,
          method: 'POST',
          body: {
            parameters: {
              ...searchParams,
            },
          },
          headers: {
            Authorization: accessTokenResponse,
          },
        });

        setData(response);
        setStatus('success');
      } catch {
        setStatus('failure');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instance]
  );

  const handleSearch: FlightPlanSearchFormSubmitHandler = useCallback(
    async values => {
      const searchParams = {
        flightNumberRange: values.flightNumberRange
          ? values.flightNumberRange
          : '',
        departureAirportCode: values.origin,
        allFlights: values.allFlights,
        aircraftType: values.aircraftType.join(),
      };

      await search(searchParams);
    },
    [search]
  );

  return {
    searchResult: status !== 'failure' ? data.flightPlans : undefined,
    status,
    onSearch: handleSearch,
  };
};

export default useSearch;
