export default {
  fuelCapacityExceeded: {
    id: 'flightPlanPortal.fuelOrderWeightTable.fuelCapacityExceeded',
    defaultMessage: 'Structural limit exceeded',
  },
  zfwExceeded: {
    id: 'flightPlanPortal.fuelOrderWeightTable.zfwExceeded',
    defaultMessage: 'Restricted limit exceeded',
  },
  towExceeded: {
    id: 'flightPlanPortal.fuelOrderWeightTable.towExceeded',
    defaultMessage: 'Restricted limit exceeded',
  },
  rmpwExceeded: {
    id: 'flightPlanPortal.fuelOrderWeightTable.rmpwExceeded',
    defaultMessage: 'Structural limit exceeded',
  },
  landingWeightExceeded: {
    id: 'flightPlanPortal.fuelOrderWeightTable.landingWeightExceeded',
    defaultMessage: 'Restricted limit exceeded',
  },
};
