import styled, { css } from 'styled-components';

import Alert from '@airnz/ui/Alert';
import TextFormField from '@airnz/ui/forms/TextFormField';

export const StyledAlert = styled(Alert)`
  ${({ theme: { spaces } }) => css`
    margin-top: ${spaces.xs};
    margin-bottom: 0;
  `}
`;

export const StyledTextField = styled(TextFormField)<{
  hasError?: boolean;
}>`
  ${({ hasError, theme: { palette } }) => css`
    input {
      width: 100%;
      text-align: right;
    }

    ${hasError &&
    `
      input{
        border: 1px solid ${palette.red};
        &:active, &:focus { 
          border: 2px solid ${palette.red};
          box-shadow: none
        }
      }
    `};

    margin-bottom: 0;

    div {
      margin-bottom: 0;
      text-align: left;
    }
  `};
`;
