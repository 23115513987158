import React from 'react';

import Skeleton from '@common/Skeleton';

import { FuelOrderWrapper } from '@modules/FlightPlanSearch/components/FuelOrder';

const FuelOrderLoader = () => (
  <FuelOrderWrapper>
    <Skeleton title="Loading" height={200}>
      <rect x="0" y="0" rx="6" ry="6" width="100%" height="47" />
      <rect x="0" y="51" rx="6" ry="6" width="100%" height="47" />
      <rect x="0" y="102" rx="6" ry="6" width="100%" height="47" />
      <rect x="0" y="153" rx="6" ry="6" width="100%" height="47" />
    </Skeleton>
  </FuelOrderWrapper>
);

export default FuelOrderLoader;
