import { createContext, useContext } from 'react';

import { StatusType } from '@common/types';

import {
  FuelOrderFlightPlan,
  SearchResultFlightPlan,
} from '@modules/FlightPlanSearch/types';

import { WeatherReport } from '@modules/FlightPlanWeather/types';

import { FuelOrderData } from '@modules/FlightPlanSearch/hooks/useFuelOrderData';

export interface FlightPlanContextOutput {
  userCanOrderFuel: boolean;
  userCanEditFuelOrder: boolean;
  isTurboProp: boolean;
  isDraftRelease: boolean;
  isOrderFuelDisabled: boolean;
  flightPlan: SearchResultFlightPlan;
  weatherReport: WeatherReport;
  weatherReportStatus: StatusType;
  fuelOrderFlightPlan?: FuelOrderFlightPlan;
  fuelOrderFetchStatus: StatusType;
  fuelOrderUpdateStatus: StatusType;
  fuelOrderData?: FuelOrderData;
  setFuelOrderData: React.Dispatch<
    React.SetStateAction<FuelOrderData | undefined>
  >;
}

export const FlightPlanContext = createContext<
  FlightPlanContextOutput | undefined
>(undefined);

const useFlightPlanContext = (): FlightPlanContextOutput => {
  const flightPlanContext = useContext(FlightPlanContext);

  if (!flightPlanContext) {
    throw new Error(
      'No FlightPlanContext.Provider found when calling useFlightPlanContext.'
    );
  }

  return flightPlanContext;
};

export default useFlightPlanContext;
