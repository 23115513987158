import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';

import Table, { THead, TBody, Tr } from '@airnz/ui/Table';
import Alert from '@airnz/ui/Alert';
import { Message } from '@airnz/ui/intl';
import { FuelOrderData } from '@modules/FlightPlanSearch/hooks/useFuelOrderData';

import {
  FuelCol,
  StyledValue,
  FuelTitle,
  FuelTitleCol,
  renderRowValue,
  FuelOrderWrapper,
  FuelOrderTextField,
  FuelOrderSwitchField,
  FuelLabel,
  FuelTitleTextWrapper,
} from '@modules/FlightPlanSearch/components/FuelOrder';

import {
  FuelOrderRow,
  FuelOrderFlightPlan,
} from '@modules/FlightPlanSearch/types';

import messages from './FuelOrderWeightTable.messages';

const FuelOrderWeightTable = ({
  flightPlan,
  fuelOrderData,
  isOrderFuelDisabled,
}: {
  flightPlan: FuelOrderFlightPlan;
  fuelOrderData: FuelOrderData;
  isOrderFuelDisabled: boolean;
}) => {
  const form = useForm();

  const {
    fpFuelRevised,
    taxiFuelRevised,
    zeroFuelWeightRevised,
    zeroFuelWeightStructural,
    rampWeightStructural,
    rampWeightRevised,
    takeOffWeightRevised,
    takeOffWeightStructural,
    abFuel,
    landingWeightRevised,
    landingWeightStructural,
    underload,
    zfwExceeded,
    towExceeded,
    rmpwExceeded,
    landingWeightExceeded,
  } = fuelOrderData;

  useEffect(() => {
    form.change(
      'revisedZeroFuelWeight',
      flightPlan.weight.zeroFuelWeight.estimatedWeight
    );

    form.change(
      'restrictedZeroFuelWeight',
      `${
        flightPlan.fuelOrder?.restrictedZeroFuelWeight ||
        flightPlan.weight.zeroFuelWeight.operationalLimit ||
        flightPlan.weight.zeroFuelWeight.structuralLimit ||
        ''
      }`
    );

    form.change(
      'restrictedTakeOffWeight',
      `${
        flightPlan.fuelOrder?.restrictedTakeOffWeight ||
        flightPlan.weight.takeOffWeight.operationalLimit ||
        flightPlan.weight.takeOffWeight.structuralLimit ||
        ''
      }`
    );

    form.change(
      'restrictedLandingWeight',
      `${
        flightPlan.fuelOrder?.restrictedLandingWeight ||
        flightPlan.weight.landingWeight.operationalLimit ||
        flightPlan.weight.landingWeight.structuralLimit ||
        ''
      }`
    );
  }, [form, flightPlan]);

  const weightRows: FuelOrderRow[] = [
    {
      id: 'zfw',
      title: 'ZFW',
      label: '(Includes UNUSABLE FUEL if advised by Flight Planning)',
      planned: zeroFuelWeightRevised,
      revisedNode: (
        <>
          <FuelOrderTextField
            name="revisedZeroFuelWeight"
            ariaLabel="Revised Zero Fuel Weight (kg)"
            isOrderFuelDisabled={isOrderFuelDisabled}
            hasValidator={false}
          />
          <StyledValue isEditable error={zfwExceeded} isWarning>
            {zfwExceeded ? (
              <div data-testid="weight_zfw_error">
                <Alert minimal type="warning">
                  <Message {...messages.zfwExceeded} />
                </Alert>
              </div>
            ) : null}
          </StyledValue>
        </>
      ),
      restrictedNode: (
        <FuelOrderTextField
          name="restrictedZeroFuelWeight"
          ariaLabel="Zero Fuel Restricted Weight (kg)"
          max={zeroFuelWeightStructural}
          isOrderFuelDisabled={isOrderFuelDisabled}
        />
      ),
      structural: zeroFuelWeightStructural,
    },
    {
      id: 'fpFuelWeight',
      title: 'TOTAL FUEL less UNUSABLE FUEL',
      revised: fpFuelRevised,
    },
    {
      id: 'rmpw',
      title: 'RMPW',
      revisedNode: (
        <StyledValue error={rmpwExceeded}>
          {renderRowValue(rampWeightRevised)}
          {rmpwExceeded ? (
            <div data-testid="weight_rpmw_error">
              <Alert minimal type="danger">
                <Message {...messages.rmpwExceeded} />
              </Alert>
            </div>
          ) : null}
        </StyledValue>
      ),
      structural: rampWeightStructural,
    },
    {
      id: 'taxi',
      title: 'TAXI OUT',
      revised: taxiFuelRevised,
    },
    {
      id: 'tow',
      title: 'TOW',
      revisedNode: (
        <StyledValue error={towExceeded} isWarning>
          {renderRowValue(takeOffWeightRevised)}
          {towExceeded ? (
            <div data-testid="weight_tow_error">
              <Alert minimal type="warning">
                <Message {...messages.towExceeded} />
              </Alert>
            </div>
          ) : null}
        </StyledValue>
      ),
      restrictedNode: (
        <FuelOrderTextField
          name="restrictedTakeOffWeight"
          ariaLabel="Take Off Restricted Weight (kg)"
          max={takeOffWeightStructural}
          isOrderFuelDisabled={isOrderFuelDisabled}
        />
      ),
      structural: takeOffWeightStructural,
    },
    {
      id: 'abFuel',
      title: 'A-B FUEL',
      revised: abFuel,
    },
    {
      id: 'lw',
      title: 'LW',
      revisedNode: (
        <StyledValue error={landingWeightExceeded} isWarning>
          {renderRowValue(landingWeightRevised)}
          {landingWeightExceeded ? (
            <div data-testid="weight_lw_error">
              <Alert minimal type="warning">
                <Message {...messages.landingWeightExceeded} />
              </Alert>
            </div>
          ) : null}
        </StyledValue>
      ),
      restrictedNode: (
        <FuelOrderTextField
          name="restrictedLandingWeight"
          ariaLabel="Landing Restricted Weight (kg)"
          max={landingWeightStructural}
          isOrderFuelDisabled={isOrderFuelDisabled}
        />
      ),
      structural: landingWeightStructural,
    },
    {
      id: 'limit',
      title: 'LIMIT',
      revised: underload?.label,
    },
    {
      id: 'underload',
      title: 'UNDERLOAD',
      revisedNode: (
        <StyledValue error={underload.value < 0}>
          {renderRowValue(underload.value)}
          {underload.value < 0 ? (
            <div data-testid="weight_underload_error" />
          ) : null}
        </StyledValue>
      ),
    },
    {
      id: 'holdTanker',
      title: 'HOLD FUEL TANKER',
      revisedNode: (
        <FuelOrderSwitchField
          name="holdTanker"
          ariaLabel="Hold Fuel Tanker"
          isOrderFuelDisabled={isOrderFuelDisabled}
        />
      ),
    },
    {
      id: 'refuelRequired',
      title: 'REFUEL REQUIRED',
      revisedNode: (
        <FuelOrderSwitchField
          name="refuelRequired"
          ariaLabel="REFUEL REQUIRED"
          isOrderFuelDisabled={isOrderFuelDisabled}
        />
      ),
    },
  ];

  return (
    <FuelOrderWrapper>
      <Table borderBottom>
        <THead>
          <Tr>
            <FuelTitle> </FuelTitle>
            <FuelTitle numeric>PLANNED </FuelTitle>
            <FuelTitle numeric>Weight (kg)</FuelTitle>
            <FuelTitle numeric>RESTRICTED</FuelTitle>
            <FuelTitle numeric>STRUCTURAL</FuelTitle>
          </Tr>
        </THead>

        <TBody>
          {weightRows.map(row => (
            <Tr key={row.title}>
              <FuelTitleCol>
                <FuelTitleTextWrapper>
                  {row.title}
                  {row.label ? <FuelLabel>{row.label}</FuelLabel> : null}
                </FuelTitleTextWrapper>
              </FuelTitleCol>

              <FuelCol numeric data-testid={`weight_${row.id}_planned`}>
                <FuelTitleTextWrapper>
                  {renderRowValue(row.planned)}
                </FuelTitleTextWrapper>
              </FuelCol>

              <FuelCol numeric data-testid={`weight_${row.id}_revised`}>
                <FuelTitleTextWrapper>
                  {row.revisedNode ?? renderRowValue(row.revised)}
                </FuelTitleTextWrapper>
              </FuelCol>

              <FuelCol numeric data-testid={`weight_${row.id}_restricted`}>
                <FuelTitleTextWrapper>
                  {row.restrictedNode}
                </FuelTitleTextWrapper>
              </FuelCol>

              <FuelCol numeric data-testid={`weight_${row.id}_structural`}>
                <FuelTitleTextWrapper>
                  {renderRowValue(row.structural)}
                </FuelTitleTextWrapper>
              </FuelCol>
            </Tr>
          ))}
        </TBody>
      </Table>
    </FuelOrderWrapper>
  );
};

export default FuelOrderWeightTable;
