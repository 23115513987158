import styled, { css } from 'styled-components';

import { Th, Td } from '@airnz/ui/Table';
import Label from '@airnz/ui/Label';

export const FuelOrderWrapper = styled.div<{ mb?: boolean }>`
  ${({ theme: { spaces }, mb }) => css`
    margin-top: ${spaces.lg};
    ${mb ? `margin-bottom: ${spaces.xl}` : ''}
  `}
`;

export const FuelLabel = styled(Label)`
  ${({ theme: { fontWeight } }) => fontWeight.regular};
`;

export const FuelTitle = styled(Th)`
  text-transform: uppercase;
`;

export const FuelTitleCol = styled(Th)`
  ${({ theme: { media } }) => css`
    min-width: 100px;

    ${media.up('lg')} {
      min-width: 200px;
    }
  `}
`;

export const FuelTitleTextWrapper = styled.div`
  height: 100%;
`;

export const FuelCol = styled(Td)`
  ${({ theme: { media, spaces } }) => css`
    width: 100px;
    min-width: 100px;
    padding-left: ${spaces.lg};

    &:nth-child(3),
    &:nth-child(4) {
      width: 50%;
    }

    ${media.up('lg')} {
      width: 150px;
      min-width: 150px;
    }
  `}
`;

export const StyledValue = styled.div<{
  error?: boolean;
  isEditable?: boolean;
  isWarning?: boolean;
}>`
  ${({ error, isEditable, isWarning, theme: { palette, spaces } }) => css`
    ${error &&
    `
      color: ${isWarning ? palette.orange : palette.red};
    `};

    ${isEditable &&
    `
      margin-top: ${spaces.xs};
      color: ${isWarning ? palette.orange : palette.black};
    `};

    div {
      margin-bottom: 0;
      text-align: left;
    }
  `};
`;
