import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import type { SelectOption } from '@airnz/ui/Select';

import { StatusType } from '@common/types';
import { getAzureAccessToken } from '@common/ApiProvider/getAzureAccessToken';
import { airportsUrl } from '@utils/apiUrls';
import { Airport } from '@modules/FlightPlanSearch/types';
import apiRequest from '@api/apiRequest';
import { useApiScopes } from '@common/ApiProvider';

export const allAirportsOption: SelectOption = {
  label: 'All airports',
  value: 'ALL',
};

const useAirport = () => {
  const [data, setData] = useState<SelectOption[]>([]);
  const [status, setStatus] = useState<StatusType>('idle');

  const { instance } = useMsal();

  const airportScopes = useApiScopes('flightPlan', [
    'fuel.order',
    'flight.plan',
  ]);

  useEffect(() => {
    const handleAirports = async () => {
      setStatus('pending');

      try {
        const accessTokenResponse = await getAzureAccessToken(
          instance,
          airportScopes
        );

        const response = await apiRequest<{ airports: Airport[] }>({
          method: 'GET',
          url: airportsUrl,
          headers: {
            Authorization: accessTokenResponse,
          },
        });

        const options: SelectOption[] = [allAirportsOption];

        const responseData = response
          ? [
              ...options,
              ...response.airports.map(airport => ({
                label: `${airport.airportName} - ${airport.airportIataCode} - ${airport.airportIcaoCode}`,
                value: airport.airportIataCode,
              })),
            ]
          : [];

        setData(responseData);
        setStatus('success');
      } catch {
        setStatus('failure');
      }
    };

    handleAirports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance]);

  return {
    defaultAirportValue: allAirportsOption.value,
    airportOptions: data,
    status,
  };
};

export default useAirport;
