const stripWhitespace = (value: string) => value.replace(/[\s]/g, '');

const flightNumberRangeNormalizer = (value: string) => {
  let output: string = value;
  // Strip out all whitespace characters
  if (value.match(/^[-\s0-9]+$/)) {
    output = stripWhitespace(value);
  }

  const isFlightNumberRange = output.match(/^\d{1,4}[-]\d{1,4}$/);
  if (isFlightNumberRange) {
    const [firstValue, secondValue] = output.split('-');
    if (firstValue === secondValue) {
      output = firstValue;
    } else if (parseInt(firstValue, 10) > parseInt(secondValue, 10)) {
      // Swap numbers if the first number is larger than the second
      output = `${secondValue}-${firstValue}`;
    }
  }
  return output;
};

export default flightNumberRangeNormalizer;
