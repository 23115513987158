import styled, { css } from 'styled-components';

export const FileList = styled.div`
  ${({ theme: { spaces, palette } }) => css`
    padding: ${spaces.md};
    background: ${palette.midnight};
    border-radius: 4px;
  `};
`;

export const FileLink = styled.a`
  ${({ theme: { spaces, palette } }) => css`
    display: block;
    padding: calc(${spaces.sm} / 2) calc(${spaces.sm} / 2);
    color: ${palette.storm};
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: ${palette.white};
    }
  `};
`;
