import React, { useEffect, useState, useRef } from 'react';

import { H3 } from '@airnz/ui/Heading';
import { IntlDateTimeUtc } from '@airnz/ui/intl';
import StatusBadge from '@airnz/ui/StatusBadge';
import SupplementaryText from '@airnz/ui/SupplementaryText';
import Form from '@airnz/ui/forms/Form';

import useUser from '@hooks/useUser';

import useFuelOrder from '@modules/FlightPlanSearch/hooks/useFuelOrder';
import { FuelOrderData } from '@modules/FlightPlanSearch/hooks/useFuelOrderData';
import { FlightPlanContext } from '@modules/FlightPlanSearch/hooks/useFlightPlanContext';
import useSearchResultsContext from '@modules/FlightPlanSearch/hooks/useSearchResultsContext';

import FlightPlan from '@modules/FlightPlanSearch/components/FlightPlan/FlightPlan';
import FuelOrderedAlert from '@modules/FlightPlanSearch/components/FuelOrderedAlert';
import {
  timeZonePhrasing,
  getIsDepartureWithin2hours,
  getIsPassedDeparture,
} from '@modules/FlightPlanSearch/utils';

import {
  SearchResultFlightPlan,
  FuelOrderFormSubmitHandler,
} from '@modules/FlightPlanSearch/types';
import { WeatherReport } from '@modules/FlightPlanWeather/types';
import { StatusType } from '@common/types';

import {
  StyledAccordionItem,
  FlightPlanHeadingRow,
  FlightPlanHeadingItem,
  FlightPlanHeadingWrapper,
} from './FlightPlanAccordionItem.styles';

const FlightPlanAccordionItem = ({
  flightPlan,
  onToggle,
  expanded,
  hasNextExpanded,
  weatherReportStatus,
  weatherReport,
}: {
  flightPlan: SearchResultFlightPlan;
  onToggle: (flightPlanId: SearchResultFlightPlan['flightPlanId']) => void;
  expanded: boolean;
  hasNextExpanded: boolean;
  weatherReportStatus: StatusType;
  weatherReport: WeatherReport;
}) => {
  const accordElem = useRef<HTMLElement>(null);

  const user = useUser();

  const flightDepatureTimeUtc =
    flightPlan.estimatedDepartureDateTime?.utc ??
    flightPlan.scheduledDepartureDateTime.utc;
  const isDepartureTimeWithin2hours = getIsDepartureWithin2hours(
    flightDepatureTimeUtc
  );
  const userCanEditFuelOrder = isDepartureTimeWithin2hours;

  const userCanOrderFuel = user?.canOrderFuel ?? false;
  const userCanOrderReadOnly = user?.canOrderFuelReadOnly;
  const isDraftRelease = flightPlan.releaseNumber === 0;

  const isTurboProp =
    flightPlan.aircraftType === 'AT76' || flightPlan.aircraftType === 'DH8C';

  const [fuelOrderData, setFuelOrderData] = useState<FuelOrderData>();
  const { timeZone } = useSearchResultsContext();

  const {
    flightPlan: fuelOrderFlightPlan,
    fetchStatus: fuelOrderFetchStatus,
    updateStatus: fuelOrderUpdateStatus,
    fetchFuelOrder,
    updateFuelOrder,
  } = useFuelOrder(flightPlan.flightPlanId);

  const isPassedDeparture = getIsPassedDeparture(flightDepatureTimeUtc);

  const isOrderFuelDisabled =
    isPassedDeparture ||
    fuelOrderUpdateStatus === 'pending' ||
    !userCanOrderFuel;

  useEffect(() => {
    if (
      (!expanded || fuelOrderFetchStatus !== 'idle') &&
      (!userCanOrderFuel || userCanOrderReadOnly)
    ) {
      return;
    }

    fetchFuelOrder();
  }, [
    expanded,
    fetchFuelOrder,
    userCanOrderFuel,
    userCanOrderReadOnly,
    fuelOrderFetchStatus,
  ]);

  const orderFuel: FuelOrderFormSubmitHandler = async values => {
    if (!userCanOrderFuel || fuelOrderData?.isInvalid) {
      return;
    }

    const formatValue = (value?: string) => (value ? Number(value) : 0);

    await updateFuelOrder({
      releaseNumber: flightPlan.releaseNumber,
      tanker: formatValue(values.tanker),
      discretionaryFuel: formatValue(values.discretionaryFuel),
      taxiFuel: formatValue(values.taxiFuel),
      payloadFuel: formatValue(values.payloadFuel),
      restrictedZeroFuelWeight: formatValue(values.restrictedZeroFuelWeight),
      revisedZeroFuelWeight: formatValue(values.revisedZeroFuelWeight),
      restrictedTakeOffWeight: formatValue(values.restrictedTakeOffWeight),
      restrictedLandingWeight: formatValue(values.restrictedLandingWeight),
      holdTanker: values.holdTanker ?? false,
      refuelRequired: values.refuelRequired ?? true,
      flightPlanRelease: `${flightPlan.releaseNumber}.${flightPlan.minorReleaseVersion}`,
    });

    if (fuelOrderUpdateStatus === 'success') {
      onToggle(flightPlan.flightPlanId);
      accordElem.current?.scrollIntoView();
    }
  };

  const initialValues = {
    releaseNumber: flightPlan.releaseNumber,
    holdTanker: flightPlan.fuelOrder?.holdTanker ?? false,
    refuelRequired: flightPlan.fuelOrder?.refuelRequired ?? true,
  };

  return (
    <FlightPlanContext.Provider
      value={{
        userCanOrderFuel,
        userCanEditFuelOrder,
        isTurboProp,
        isDraftRelease,
        isOrderFuelDisabled,
        flightPlan,
        weatherReport,
        weatherReportStatus,
        fuelOrderFlightPlan,
        fuelOrderFetchStatus,
        fuelOrderUpdateStatus,
        fuelOrderData,
        setFuelOrderData,
      }}
    >
      <StyledAccordionItem
        forceFullWidth
        expanded={expanded}
        heading={
          <H3>
            {flightPlan.commercialFlightNumber}{' '}
            {flightPlan.departureAirport.airportIataCode} -{' '}
            {flightPlan.destinationAirport.airportIataCode}
            <span ref={accordElem}> </span>
          </H3>
        }
        renderHeading={({ children }) => (
          <FlightPlanHeadingWrapper>
            <FlightPlanHeadingRow>
              <FlightPlanHeadingItem>{children}</FlightPlanHeadingItem>
              <FlightPlanHeadingItem>
                {isDraftRelease ? (
                  <StatusBadge type="warning">
                    Draft {flightPlan.releaseNumber}.
                    {flightPlan.minorReleaseVersion}
                  </StatusBadge>
                ) : (
                  <StatusBadge type="success">
                    REL {flightPlan.releaseNumber}.
                    {flightPlan.minorReleaseVersion}
                  </StatusBadge>
                )}
              </FlightPlanHeadingItem>
              {flightPlan.fuelOrder || fuelOrderFlightPlan?.fuelOrder ? (
                <FlightPlanHeadingItem>
                  <FuelOrderedAlert />
                </FlightPlanHeadingItem>
              ) : null}
            </FlightPlanHeadingRow>

            <FlightPlanHeadingRow>
              <FlightPlanHeadingItem>
                <SupplementaryText>
                  <strong>STD ({timeZonePhrasing(timeZone)}):</strong>{' '}
                  <IntlDateTimeUtc
                    value={flightPlan.scheduledDepartureDateTime.utc}
                    timeZone={timeZone}
                    hourCycle="h23"
                  />
                </SupplementaryText>
                {flightPlan.fuelOrder || fuelOrderFlightPlan?.fuelOrder ? (
                  <>
                    <SupplementaryText>
                      <b>Fuel ordered by: </b>
                      {fuelOrderFlightPlan?.fuelOrder?.submittedBy ||
                        flightPlan.fuelOrder?.submittedBy}
                    </SupplementaryText>
                    <SupplementaryText>
                      <b>Fuel ordered at ({timeZonePhrasing(timeZone)}):</b>{' '}
                      <IntlDateTimeUtc
                        value={
                          fuelOrderFlightPlan?.fuelOrder?.submittedDateTime.utc
                            ? fuelOrderFlightPlan.fuelOrder.submittedDateTime
                                .utc
                            : flightPlan.fuelOrder?.submittedDateTime.utc ?? ''
                        }
                        timeZone={timeZone}
                        hourCycle="h23"
                      />
                    </SupplementaryText>
                  </>
                ) : null}
              </FlightPlanHeadingItem>
            </FlightPlanHeadingRow>
          </FlightPlanHeadingWrapper>
        )}
        onToggle={() => onToggle(flightPlan.flightPlanId)}
        hasNextExpanded={hasNextExpanded}
      >
        <Form initialValues={initialValues} onSubmit={orderFuel}>
          <FlightPlan />
        </Form>
      </StyledAccordionItem>
    </FlightPlanContext.Provider>
  );
};

export default FlightPlanAccordionItem;
