import styled, { css } from 'styled-components';

import AccordionItem from '@airnz/ui/AccordionItem';

export const StyledAccordionItem = styled(AccordionItem)`
  > div {
    overflow: visible;
  }
`;

export const FlightPlanHeadingWrapper = styled.div`
  ${({ theme: { spaces } }) => css`
    width: 100%;
    padding-right: ${spaces.md};
  `}
`;

export const FlightPlanHeadingRow = styled.div`
  ${({ theme: { media } }) => css`
    display: flex;
    justify-content: space-between;

    ${media.up('md')} {
      justify-content: flex-start;
    }
  `}
`;

export const FlightPlanHeadingItem = styled.div`
  ${({ theme: { spaces } }) => css`
    margin-right: ${spaces.lg};

    &:first-child {
      min-width: 210px;
    }
  `}
`;
