import React from 'react';
import styled, { css } from 'styled-components';

import Switch from '@airnz/ui/Switch';
import useFormField from '@airnz/ui/forms/useFormField';

const StyledSwitch = styled(Switch)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      :hover {
        cursor: not-allowed;
      }
    `}
`;

const FuelOrderSwitchField = ({
  name,
  ariaLabel,
  isOrderFuelDisabled,
}: {
  name: string;
  ariaLabel: string;
  isOrderFuelDisabled: boolean;
}) => {
  const fieldProps = useFormField<boolean>({
    name,
  });

  return (
    <StyledSwitch
      {...fieldProps}
      ariaLabel={ariaLabel}
      disabled={isOrderFuelDisabled}
    />
  );
};

export default FuelOrderSwitchField;
