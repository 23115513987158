import React from 'react';

import Button from '@airnz/ui/Button';
import ChevronDown from '@airnz/ui/icons/ChevronDown';
import Popover, { PopoverPlacement } from '@airnz/ui/Popover';
import useToggle from '@airnz/ui/useToggle';

import { WeatherReport } from '@modules/FlightPlanWeather/types';
import usePdfDownloader from '@hooks/usePdfDownloader';
import { FileLink, FileList } from '@common/styles/FileDownload.styles';

const WeatherChartsPopover = ({
  reports,
  placement = { x: 'right', y: 'below' },
  loading,
}: {
  reports: WeatherReport;
  placement?: PopoverPlacement;
  loading?: boolean;
}) => {
  const [open, toggleOpen] = useToggle(false);

  const { downloadPdf, downloadStatus } = usePdfDownloader();

  const weatherCharts = reports.weatherReports.filter(
    report => report.type !== 'WeatherBrief'
  );

  return (
    <Popover
      offsetY={5}
      anchor={
        <Button
          icon={<ChevronDown />}
          iconPosition="end"
          onClick={toggleOpen}
          inProgress={downloadStatus === 'pending' || loading}
        >
          SIG Weather Charts
        </Button>
      }
      open={open}
      placement={placement}
      onEscapePress={toggleOpen}
      onOutsideAction={toggleOpen}
    >
      <FileList>
        {weatherCharts.map(file => (
          <FileLink
            key={file.reportName}
            target="_blank"
            onClick={event => {
              open && toggleOpen();
              downloadPdf({ event, weatherChart: reports, file });
            }}
          >
            {file.reportName}
          </FileLink>
        ))}
      </FileList>
    </Popover>
  );
};

export default WeatherChartsPopover;
