export default {
  emptyAircraftTypesError: {
    id: 'emptyAircraftTypesError',
    defaultMessage: 'Please select at least one aircraft type.',
  },
  airportAndAircraftError: {
    id: 'flightPlanPortal.flightPlanSearch.airportAndAircraftError',
    defaultMessage: 'Fetching departure airports and aircraft types failed.',
  },
  airportAndAircraftEmpty: {
    id: 'flightPlanPortal.flightPlanSearch.airportAndAircraftEmpty',
    defaultMessage: 'Fetching airports and/or aircraft types returned no data.',
  },
  searchResultsError: {
    id: 'flightPlanPortal.flightPlanSearch.searchResultsError',
    defaultMessage: 'Something went wrong trying to fetch the results',
  },
};
