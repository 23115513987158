import { useCallback } from 'react';

const delimiter = '-';

const useFlightNumberRangeValidator = () => {
  const validator = useCallback((value: string) => {
    const regex = RegExp(/^\d{1,4}([-]\d{1,4})?$/);
    const zeroDigitRegex =
      RegExp(/^0*$/) && RegExp(/^[0]{1,4}([-])?([0]{1,4})?$/);

    if (regex.test(value) && !zeroDigitRegex.test(value)) {
      return undefined;
    }

    const singleFlightNumber = value.indexOf(delimiter) === -1;

    const validFlightNumber = regex.test(value);

    return validFlightNumber || !singleFlightNumber
      ? 'Please enter a valid flight number range. e.g. 1212-3434'
      : 'Flight number must not exceed four digits.';
  }, []);

  return validator;
};

export default useFlightNumberRangeValidator;
