import styled, { css } from 'styled-components';

import { H2 } from '@airnz/ui/Heading';

export const TitleBar = styled.div`
  ${({ theme: { spaces, media } }) => css`
    margin-bottom: ${spaces.md};

    ${media.up('md')} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `}
`;

export const ResultsLastChangedDate = styled.div`
  ${({ theme: { fontSize } }) => css`
    ${fontSize.xs}
  `}
`;

export const ResultsActions = styled.div`
  ${({ theme: { media } }) => css`
    ${media.up('md')} {
      display: flex;
      align-items: center;
    }
  `}
`;

export const ResultsAction = styled.div`
  ${({ theme: { spaces, media } }) => css`
    margin-top: ${spaces.md};

    ${media.up('md')} {
      margin-top: 0;
      margin-left: ${spaces.sm};
    }
  `}
`;

export const StyledH2 = styled(H2)`
  margin-bottom: 0;
`;
