import { useMemo } from 'react';

import useFormFieldValue from '@airnz/ui/forms/useFormFieldValue';

import { FuelOrderFlightPlan } from '@modules/FlightPlanSearch/types';

export interface FuelOrderData {
  minimumFlightPlanFuel: number;
  minimumReserveFuel: number;
  maximumFuelCapacity: number;
  tanker: number;
  tankerRevised: number;
  tankerRevisedValue: string;
  fpExtra: number;
  discretionaryFuel: number;
  discretionaryFuelRevised: number;
  discretionaryFuelRevisedValue: string;
  taxiFuel: number;
  taxiFuelRevised: number;
  taxiFuelRevisedValue: string;
  payloadFuel: number;
  payloadFuelRevised: number;
  payloadFuelRevisedValue: string;
  toFuel: number;
  toFuelRevised: number;
  fpFuel: number;
  fpFuelRevised: number;
  totalTankFuel: number;
  totalTankFuelRevised: number;
  zeroFuelWeight: number;
  zeroFuelWeightRevised: number;
  zeroFuelWeightRestrictedValue: string;
  zeroFuelWeightOperational?: number;
  zeroFuelWeightStructural: number;
  zeroFuelWeightRestricted: number;
  rampWeight: number;
  rampWeightStructural: number;
  rampWeightRevised: number;
  takeOffWeight: number;
  takeOffWeightRevised: number;
  takeOffWeightRestrictedValue: string;
  takeOffWeightOperational?: number;
  takeOffWeightStructural: number;
  abFuel: number;
  landingWeight: number;
  landingWeightRevised: number;
  landingWeightRestrictedValue: string;
  landingWeightOperational?: number;
  landingWeightStructural: number;
  underload: {
    label: string;
    value: number;
  };
  fuelCapacityExceeded: boolean;
  zfwExceeded: boolean;
  towExceeded: boolean;
  rmpwExceeded: boolean;
  landingWeightExceeded: boolean;
  isInvalid: boolean;
}

const useFuelOrderData = (flightPlan: FuelOrderFlightPlan) => {
  const tankerRevisedValue = useFormFieldValue<string>('tanker');

  const discretionaryFuelRevisedValue =
    useFormFieldValue<string>('discretionaryFuel');

  const taxiFuelRevisedValue = useFormFieldValue<string>('taxiFuel');

  const payloadFuelRevisedValue = useFormFieldValue<string>('payloadFuel');

  const zeroFuelWeightRestrictedValue = useFormFieldValue<string>(
    'restrictedZeroFuelWeight'
  );

  const zeroFuelWeightRevisedValue = useFormFieldValue<string>(
    'revisedZeroFuelWeight'
  );

  const takeOffWeightRestrictedValue = useFormFieldValue<string>(
    'restrictedTakeOffWeight'
  );

  const landingWeightRestrictedValue = useFormFieldValue<string>(
    'restrictedLandingWeight'
  );

  return useMemo((): FuelOrderData => {
    const minimumFlightPlanFuel =
      flightPlan.fuel.minimumFlightPlanFuel.estimated;

    const minimumReserveFuel = flightPlan.fuel.minimumReserveFuel.estimated;

    const maximumFuelCapacity = flightPlan.fuel.maximumFuelCapacity;

    const tanker = minimumReserveFuel;
    const tankerRevised =
      tankerRevisedValue !== ''
        ? Number(tankerRevisedValue)
        : flightPlan.fuelOrder?.tanker ?? tanker;

    const fpExtra = flightPlan.fuel.flightPlannerExtraFuel.estimated;

    const discretionaryFuel = 0;

    const discretionaryFuelRevised =
      discretionaryFuelRevisedValue !== ''
        ? Number(discretionaryFuelRevisedValue)
        : flightPlan.fuelOrder?.discretionaryFuel ?? discretionaryFuel;

    const taxiFuel = flightPlan.fuel.taxiFuel.estimated;

    const taxiFuelRevised =
      taxiFuelRevisedValue !== ''
        ? Number(taxiFuelRevisedValue)
        : flightPlan.fuelOrder?.taxiFuel ?? taxiFuel;

    const payloadFuel = 0;

    const payloadFuelRevised =
      payloadFuelRevisedValue !== ''
        ? Number(payloadFuelRevisedValue)
        : flightPlan.fuelOrder?.payloadFuel ?? payloadFuel;

    const toFuel =
      minimumFlightPlanFuel + minimumReserveFuel + discretionaryFuel + fpExtra;

    const toFuelRevised =
      minimumFlightPlanFuel +
      minimumReserveFuel +
      (discretionaryFuelRevised ?? discretionaryFuel) +
      (tankerRevised - tanker) +
      fpExtra;

    const fpFuel = toFuel + taxiFuel;

    const fpFuelRevised = toFuelRevised + (taxiFuelRevised ?? taxiFuel);

    const totalTankFuel = toFuel + taxiFuel + payloadFuel;

    const totalTankFuelRevised =
      toFuelRevised +
      (taxiFuelRevised ?? taxiFuel) +
      (payloadFuelRevised ?? payloadFuel);

    const zeroFuelWeight = flightPlan.weight.zeroFuelWeight.estimatedWeight;

    const zeroFuelWeightRevised =
      flightPlan.weight.zeroFuelWeight.estimatedWeight;

    const zeroFuelWeightOperational =
      flightPlan.weight.zeroFuelWeight.operationalLimit;

    const zeroFuelWeightStructural =
      flightPlan.weight.zeroFuelWeight.structuralLimit;

    const zeroFuelWeightRestricted =
      zeroFuelWeightRestrictedValue !== '' &&
      zeroFuelWeightRestrictedValue !== '0'
        ? Number(zeroFuelWeightRestrictedValue)
        : zeroFuelWeightOperational ?? zeroFuelWeightStructural;

    // Numeric value for zero fuel weight revise field.
    const zeroFuelWeightEstimated =
      zeroFuelWeightRevisedValue !== '' && zeroFuelWeightRevisedValue !== '0'
        ? Number(zeroFuelWeightRevisedValue)
        : zeroFuelWeightOperational ?? zeroFuelWeightRevised;

    const rampWeight = fpFuel + zeroFuelWeightEstimated;

    const rampWeightStructural = flightPlan.weight.rampWeight.structuralLimit;

    const rampWeightRevised = fpFuelRevised + zeroFuelWeightEstimated;

    const takeOffWeight = flightPlan.weight.takeOffWeight.estimatedWeight;

    const takeOffWeightRevised = rampWeightRevised - taxiFuelRevised;

    const takeOffWeightOperational =
      flightPlan.weight.takeOffWeight.operationalLimit;

    const takeOffWeightStructural =
      flightPlan.weight.takeOffWeight.structuralLimit;

    const takeOffWeightRestricted =
      takeOffWeightRestrictedValue !== '' &&
      takeOffWeightRestrictedValue !== '0'
        ? Number(takeOffWeightRestrictedValue)
        : takeOffWeightOperational ?? takeOffWeightStructural;

    const abFuel = flightPlan.fuel.abFuel.estimated;

    const landingWeight = flightPlan.weight.landingWeight.estimatedWeight;

    const landingWeightRevised =
      takeOffWeightRevised - flightPlan.fuel.abFuel.estimated;

    const landingWeightOperational =
      flightPlan.weight.landingWeight.operationalLimit;

    const landingWeightStructural =
      flightPlan.weight.landingWeight.structuralLimit;

    const landingWeightRestricted =
      landingWeightRestrictedValue !== '' &&
      landingWeightRestrictedValue !== '0'
        ? Number(landingWeightRestrictedValue)
        : landingWeightOperational ?? landingWeightStructural;

    const underload = [
      {
        label: 'ZFW',
        value:
          zeroFuelWeightRestricted -
          (zeroFuelWeightEstimated || zeroFuelWeight),
      },
      {
        label: 'TOW',
        value:
          takeOffWeightRestricted - (takeOffWeightRevised || takeOffWeight),
      },
      {
        label: 'LW',
        value:
          landingWeightRestricted - (landingWeightRevised || landingWeight),
      },
    ].reduce((a, b) => (a.value < b.value ? a : b));

    const fuelCapacityExceeded =
      totalTankFuelRevised > flightPlan.fuel.maximumFuelCapacity;

    const zfwExceeded = zeroFuelWeightEstimated > zeroFuelWeightRestricted;

    const towExceeded = takeOffWeightRevised > takeOffWeightRestricted;

    const rmpwExceeded = rampWeightRevised > rampWeightStructural;

    const landingWeightExceeded =
      landingWeightRevised > landingWeightRestricted;

    const isInvalid = fuelCapacityExceeded;

    return {
      minimumFlightPlanFuel,
      minimumReserveFuel,
      maximumFuelCapacity,
      tanker,
      tankerRevised,
      tankerRevisedValue,
      fpExtra,
      discretionaryFuel,
      discretionaryFuelRevised,
      discretionaryFuelRevisedValue,
      taxiFuel,
      taxiFuelRevised,
      taxiFuelRevisedValue,
      payloadFuel,
      payloadFuelRevised,
      payloadFuelRevisedValue,
      toFuel,
      toFuelRevised,
      fpFuel,
      fpFuelRevised,
      totalTankFuel,
      totalTankFuelRevised,
      zeroFuelWeight,
      zeroFuelWeightRevised,
      zeroFuelWeightRestrictedValue,
      zeroFuelWeightOperational,
      zeroFuelWeightStructural,
      zeroFuelWeightRestricted,
      rampWeight,
      rampWeightStructural,
      rampWeightRevised,
      takeOffWeight,
      takeOffWeightRevised,
      takeOffWeightRestrictedValue,
      takeOffWeightOperational,
      takeOffWeightStructural,
      abFuel,
      landingWeight,
      landingWeightRevised,
      landingWeightRestrictedValue,
      landingWeightOperational,
      landingWeightStructural,
      underload,
      fuelCapacityExceeded,
      zfwExceeded,
      towExceeded,
      rmpwExceeded,
      landingWeightExceeded,
      isInvalid,
    };
  }, [
    flightPlan,
    tankerRevisedValue,
    discretionaryFuelRevisedValue,
    taxiFuelRevisedValue,
    payloadFuelRevisedValue,
    zeroFuelWeightRevisedValue,
    zeroFuelWeightRestrictedValue,
    takeOffWeightRestrictedValue,
    landingWeightRestrictedValue,
  ]);
};

export default useFuelOrderData;
