import React, { useContext } from 'react';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

interface LoaderProps {
  title: string;
  height: number;
}

const Skeleton = ({
  children,
  title,
  height,
}: React.PropsWithChildren<LoaderProps>) => {
  const {
    palette: { steel, steam },
  } = useContext(ThemeContext);

  return (
    <ContentLoader
      speed={2.2}
      width="100%"
      height={height}
      backgroundColor={steel}
      foregroundColor={steam}
      title={title}
    >
      {/* Only SVG shapes */}
      {children}
    </ContentLoader>
  );
};

export default Skeleton;
